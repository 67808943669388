@use "sass:map";
@font-face {
  font-family: 'Hiragino Kaku Gothic Pro';
  src: url('./fonts/hiragino-kaku-gothic-pro-w3.otf');
}
// @font-face {
//   font-family: "Hiragino Kaku Gothic Pro";
//   src: url("./fonts/hiragino-kaku-gothic-pro-w6.otf");
// }

body {
  font-family: 'Hiragino Kaku Gothic Pro';
  background: #f5f5f5;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.page-container {
  margin: 20px auto;
  min-height: 660px;
  background: #fafafa;
  width: 960px;
}
.btn-action {
  border-radius: 10px;
  padding: 8.5px;
}

.btn-white {
  border-color: map.get($theme-colors, 'primary');
  color: map.get($theme-colors, 'primary');
  &:hover,
  &:focus {
    border-color: map.get($theme-colors, 'primary');
    color: map.get($theme-colors, 'primary');
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    border-color: map.get($theme-colors, 'primary');
    color: map.get($theme-colors, 'primary');
  }
}
.btn.disabled{
  cursor: no-drop;
}

/**
customize form
*/
form label {
  font-weight: bold;
  color: #424242;
}

form .form-feed-back-error {
  font-size: 0.786rem;
}

form .custom-form-text {
  font-size: 0.786rem;
  color: #9e9e9e !important;
}

.form-control:focus {
  border-width: 2px;
}

input.is-empty {
  border-color: #e0e0e0;
}

input:disabled {
  border-color: #f5f5f5;
  cursor: no-drop;
}

/**
end customize form
*/

.btn-gray-out {
  background-color: #e0e0e0 !important;
  color: #bdbdbd !important;
  border-color: #e0e0e0 !important;
}

.card {
  // box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  box-shadow: none;
  .title-box-inp {
    font-size: 16px !important;
  }
}

.card.box-item_left {
  border: 1px solid #E0E0E0 !important;
}

.bg-box-input {
  background: #eef5f9;
}

.custom-btn-link {
  font-weight: bold;
  color: #0091ea !important;
}

.btn-default {
  width: 230px;
  height: 40px;
  background-color: map.get($theme-colors, 'primary');
  border-radius: 10px;
  color: map.get($theme-colors, 'white');
  outline: none;
}
.auth-pages-container {
  margin: 20px auto;
  padding-top: 40px;
  background-color: #fafafa;
  width: 960px;
}

.title_global {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 30px;
  color: #424242;
}

.header-page {
  margin-bottom: 30px;
  .text-title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #424242;
  }
}
.form-control {
  &:focus {
    box-shadow: unset;
  }

  &.is-invalid,
  .was-validated .form-control:invalid {
    background-image: none !important;
    &:focus {
      box-shadow: unset;
    }
  }
}
.simulation-information-container {
  padding: 41px 200px 51px 200px;
  .tab-content {
    .tab-pane {
      .content-top {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 80px;
      }
      .content-bottom {
        line-height: 21px;
        margin-bottom: 84px;
      }
    }
  }
}
.common-simulation-input-form {
  .form-control.is-invalid {
    border-color: #ff5252;
    padding-right: calc(-1.5em + 1.94rem);
  }
  .title-box-inp {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }
  .title_box_blue {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #0091ea;
    align-items: center;
  }
  .form-group-btn {
    button {
      width: 230px;
    }
    margin-top: 50px;
    margin-bottom: 0;
  }
  .word-keep {
    word-break: keep-all;
  }
  .mr-25 {
    margin-right: 25px !important;
  }
  .w-80 {
    width: 70% !important;
  }
  .w-10 {
    width: 10% !important;
  }
  .w-30 {
    width: 30% !important;
  }
  .box-inline {
    display: flex;
    align-items: baseline;
  }
  .box-inline > * {
    margin-right: 10px;
  }
  .custom-line {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .box-item .item-fee:not(:first-child) {
    margin-top: 10px;
  }
}
input[type=number]{
  text-align: right;
}

// Simulation result table
.annual-income-and-expenditure-table {
  color: #424242;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 41px;
  min-width: 2310px;

  .th-cell-other {
    background: #F5F5F5;
    border-color: #F5F5F5;
  }

  tr:nth-child(even) .td-cell-other {
    background: #F5F5F5;
    border-color: #F5F5F5;
  }

  tr:nth-child(even) .td-cell-age {
    background: #E0F2F1;
    border-color: #E0F2F1;
  }

  tr:nth-child(even) .td-cell-income {
    background: #E0F7FA;
    border-color: #E0F7FA;
  }

  tr:nth-child(even) .td-cell-spending {
    background: #FFEBEE;
    border-color: #FFEBEE;
  }

  .th-cell-age {
    background: #10A187;
    border-color: #10A187;
  }

  .th-cell-age-child {
    background: #00BFA5;
    border-color: #00BFA5;
    min-width: 50px;
    // max-width: 73px;
  }

  .th-cell-income {
    background: #0091A9;
    border-color: #0091A9;
  }

  .th-cell-president-spouse {
    background: #00A7C1;
    border-color: #00A7C1;
  }

  .th-cell-president-spouse-child {
    background: #00B8D4;
    border-color: #00B8D4;
  }

  .th-cell-income-meter-and-other-income {
    background: #00B8D4;
    border-color: #00B8D4;
  }

  .th-cell-spending {
    background: #C12E34;
    border-color: #C12E34;
  }

  .th-cell-spending-child {
    background: #EE494F;
    border-color: #EE494F;
  }

  tr {
    th {
      vertical-align : middle;
    }

    td {
      vertical-align : bottom;
    }

    th, td {
      font-weight: normal;
      font-size: 12px;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      padding-left: 8px !important;
      padding-right: 8px !important;
      border: none !important;
    }
  }
}

// Custom select icon
select {
  background: url(images/br_down.png) right 10.500px center no-repeat;
  appearance: none;
  -webkit-appearance: none; // Support for Google Chrome và Safari.
  -moz-appearance: none; // Support for firefox.
  -ms-appearance: none; // Support for Internet Explorer.
  -o-appearance: none; // Support for Opera.
  min-width: 80px;
}


