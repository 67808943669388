$theme-colors: (
  "primary": #0091ea,
  "danger": #FF5252,
  "success": #00C853,
  "white": #FAFAFA
);

// $font-size-lg:  1rem  !default;

// Button
$btn-border-radius:           0.357rem;
$btn-border-radius-sm: $btn-border-radius;
$btn-padding-y-sm: .358rem !default;
$btn-padding-x-sm: .75rem !default;
$btn-font-weight: bold !default;

// Input
$input-border-radius: 5px !default;
$input-bg: #FAFAFA !default;
$input-border-color:                    #0091EA !default;
$input-border-width:                    1px !default;
$input-color: #424242 !default;
$input-padding-y:         0.97rem !default;
$input-placeholder-color:               #BDBDBD !default;
$input-disabled-bg:                     #F5F5F5 !default;
$input-focus-border-color:              #0091EA !default;
$input-focus-width:                     0.143rem !default;

$input-empty-border-color: #E0E0E0;

// Text color
$yiq-text-light: #fafafa;

// Card
$card-border-width:                 0 !default;
$card-border-radius: 0.714rem !default;
$card-bg: #fafafa;
$card-cap-bg: $card-bg;
// box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);

// Pagination
$pagination-active-color:           #FAFAFA !default;
$pagination-bg:                     #f5f5f5 !default;
$pagination-border-color:           #0091EA !default;
$pagination-hover-border-color:     #0091EA !default;
$pagination-padding-y:              0.429rem !default;
$pagination-padding-x:              0.857rem !default;
$pagination-padding-y-sm:           0.429rem !default; 
$pagination-padding-x-sm:           0.857rem !default;
$pagination-padding-y-lg:           0.429rem !default;
$pagination-padding-x-lg:           0.857rem !default;

// Label
$label-margin-bottom:                   0.643rem !default;

// Form group
$form-group-margin-bottom:              2.143rem !default;
