// customize bootstrap
html { font-size: 14px; }
@import "custom.scss";

// bootstrap import
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/variables";


// LAYOUT
@import "layouts/header.scss";
@import "layouts/footer.scss";
// Block loading
@import "components/block-loading/block-loading";

// Common
@import "common.scss";
